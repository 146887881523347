@import 'variables';
@import 'mixins';
@import 'plugins/plugin-perfect-scrollbar';
@import 'plugins/plugin-react-chartist';

* {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  line-height: 1.5em;
  color: inherit;
}

h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
}

h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}

h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}

h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
}

h6 {
  font-size: 0.75rem;
  font-weight: 500;
}

p {
  font-size: 14px;
  margin: 0 0 10px;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.relative {
  position: relative;
}

.clearfix  {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.item--flex {
  flex: 1;
}

.txt--left {
  text-align: left !important;
}

.txt--center {
  text-align: center !important;
}

.txt--right {
  text-align: right !important;
}

.page--simple {
  min-height: calc(100vh - 162px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.page--other {
  min-height: calc(100vh - 162px);
}

.page--full-relative {
  position: relative;
  height: 100vh;
  .ispinner,
  .bubble--spinner {
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .ispinner {
    margin: -10px 0 0 -10px;
  }
  .bubble--spinner {
    margin: -30px 0 0 -30px;
  }
}

.perfect-scrollbar {
  .layout {
    height: 100vh;
  }
  .sidebar__nav {
    height: calc(100vh - 64px);
  }
}

.wrap__inner {
  width: 100%;
  padding: 24px;
}

.line__dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ispinner {
  position: relative;
  width: 20px;
  height: 20px;

  .ispinner__blade {
    position: absolute;
    left: 44.5%;
    top: 37%;
    width: 10%;
    height: 25%;
    border-radius: 50%/20%;
    animation: ispinner-fade 1s linear infinite;
    animation-play-state: paused;

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        animation-delay: #{0.08334*$i + -1.75001}s;
        transform: rotate(#{$i * 30}deg) translate(0, -150%);
      }
    }
  }

  &.ispinner--animating .ispinner__blade {
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }

  &.ispinner--large {
    width: 35px;
    height: 35px;

    .ispinner__blade {
      width: 8.57143%;
      height: 25.71429%;
      border-radius: 50%/16.67%;
    }
  }
}

.bubble--spinner {
  height: 60px;
  width: 60px;
  .bubble__pulse {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    animation: scaleout 1.0s infinite ease-in-out;
  }
}

.logo--center-page {
  height: 40vmin;
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
    animation: logo-spin infinite 20s linear;
  }
}

.nbar {
  position: absolute;
  z-index: 1000;
  right: 25px;
  top: 32px;
  font-family: inherit;
  font-size: 14px;
  display: flex;
  align-items: center;
  @media (max-width: 899px) {
    right: 62px;
    top: 34px;
  }
  @media (max-width: 599px) {
    top: 27px;
  }
  > *:not(:first-child) {
    margin-left: 10px;
  }
}


@keyframes ispinner-fade {
  0% {
    opacity: 0.85;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}

@keyframes scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
