.ct-chart-white-colors {
  .ct-grid {
    stroke: rgba(255, 255, 255, 0.2);
    stroke-width: 1px;
    stroke-dasharray: 2px;
  }

  .ct-series-a {
    .ct-point, .ct-line, .ct-bar, .ct-slice-donut {
      stroke: rgba(255, 255, 255, 0.8);
    }
  }

  .ct-label {
    &.ct-horizontal.ct-end {
      -webkit-box-align: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: flex-start;
      align-items: flex-start;
      -webkit-box-pack: flex-start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: flex-start;
      justify-content: flex-start;
      text-align: left;
      text-anchor: start;
    }

    color: rgba(255, 255, 255, 0.7);
  }
}

.ct-chart-line .ct-label, .ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}

.ct-series-a {
  .ct-point, .ct-line, .ct-bar, .ct-slice-donut {
    stroke: #00bcd4;
  }

  .ct-slice-pie, .ct-slice-donut-solid, .ct-area {
    fill: #00bcd4;
  }
}
